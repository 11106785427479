<template>
  <layout>
    <section class="breadcrumbs-bg">
        <div class="container">
            <h1 v-if="pageHeading">{{ pageHeading }}</h1>
        </div>
    </section>

    <section class="inner-page-section py-5">
        <div class="container" v-if="pageData" v-html="pageData.page_data"></div>
        <div class="container" v-if="pdfPath">
          <PDFJSViewer :page="`${page}`" :catalogPath="`${pdfPath}`"/>
        </div>
        <div class="container" v-if="isNotFound">
          <NotFoundElement />
        </div>
    </section>

    <GetInTouchElement v-if="!isNotFound"></GetInTouchElement>
  </layout>
</template>
 
<script>
import Layout from '../Layout/Layout.vue';
import GetInTouchElement from '../Elements/GetInTouchElement.vue';
import PDFJSViewer from '../Elements/PDFJSViewer'
import NotFoundElement from '../Elements/NotFound';
import axios from 'axios';
import $ from 'jquery';
 
export default {
  name: 'CustomPageComponent',
  components: {
    Layout,
    GetInTouchElement,
    PDFJSViewer,
    NotFoundElement
  },
  data(){
    return {
      pageData: {},
      pageHeading: '',
      pdfPath: '',
      page: 1,
      isNotFound: false
    }
  },
  computed: {
    slug() {
      return this.$route.params.slug;
    },
    categoryslug() {
      return this.$route.params.categoryslug;
    },
    storeInformationData() {
      return this.$store.state.storeInformationData;
    }
  },
  watch: {
    storeInformationData() {
      this.checkAndUpdatePageTitle();
    },
    pageData() {
      this.checkAndUpdatePageTitle();
    }
  },
  created() {
    if(this.slug.toLowerCase() == 'pdfs' && this.categoryslug != '') {
      if(this.categoryslug){
        this.fetchPageData(this.categoryslug);
      } else {
        this.setNotFoundPage();
      }
    } else if(this.slug.toLowerCase() != 'pdfs' && this.categoryslug != '') {
      this.setNotFoundPage();
    } else {
      var dbslug = this.slug;
      if(this.categoryslug){
        dbslug = this.categoryslug;
      }
      this.fetchPageData(dbslug);
    }
  },
  methods: {
    fetchPageData(slug) {
      //axios.post('/get-page-data', { slug: slug })
      axios.get(`/get-page-data/${slug}`)
        .then(response => {
          const responseData = response.data.data[0];
          if (responseData) {
            if(responseData.page_type == 1) {
              this.pdfPath = `/lib/pdfjs/web/viewer.html?file=/${responseData.page_file}`;
            } if(responseData.page_type == 2) {
              window.location.href = responseData.page_link;
            } else {
              this.pageData = responseData;
              let pageHtml = $.parseHTML(responseData.page_data)[0]?.data || '';
              pageHtml = pageHtml.replace('../images/', process.env.VUE_APP_IMG_URL + '/images/');
              this.pageData.page_data = pageHtml;
            }
            this.pageHeading = responseData.page_name
            document.title = 'LXG - ' + responseData.page_name;
          } else {
            this.setNotFoundPage();
          }
        })
        .catch(error => {
          console.error('Error fetching page data:', error);
          this.setNotFoundPage();
        });
    },
    setNotFoundPage() {
      /*document.title = 'LXG - Page Not Found';
      this.pageData = {
        page_data: '<p class="text-center">The page you are looking for does not exist.</p>',
        page_name: '404 - Page Not Found'
      };*/
      //this.$router.push({ name: 'NotFound' });
      this.pageHeading = '404 - Page Not Found';
      document.title = 'LXG - Page Not Found';
      this.isNotFound = true;
    },
    checkAndUpdatePageTitle() {
      if (this.storeInformationData && this.pageData && this.storeInformationData.store_name && this.pageData.page_name) {
        document.title = this.storeInformationData.store_name + ' - ' + this.pageData.page_name;
      }
    }
  },
  mounted() {
    document.body.classList.add('inner-page-bg');
  },
  beforeUnmount() {
    document.body.classList.remove('inner-page-bg');
  }
};
</script>