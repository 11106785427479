<template>
  <div>
    <component :is="componentToRender" v-bind="componentProps"></component>
  </div>
</template>

<script>
import { markRaw } from 'vue';
import ComingSoonComponent from './ComingSoon.vue';
import ProductsComponent from './Products.vue';
import CustomPageComponent from './CustomPage.vue';
import axios from 'axios';

export default {
  name: 'PageComponent',
  data() {
    return {
      componentToRender: null,
      pageData: null,
    };
  },
  computed: {
    paramsData() {
      return {
        slug: this.$route.params.slug,
        categoryslug: this.$route.params.categoryslug
      };
    },
    componentProps() {
      //if (this.paramsData.slug != '' && (this.paramsData.slug == 'products' || this.paramsData.categoryslug != '')) {
      if (this.paramsData.slug != '' && this.paramsData.slug == 'products' && this.paramsData.categoryslug != '') {
        return { categoryslug: this.paramsData.categoryslug };
      }
      return {};
    }
  },
  watch: {
    '$route.params': 'determineComponentToRender'
  },
  created() {
    this.determineComponentToRender();
  },
  methods: {
    async determineComponentToRender() {
      try {
        //if (this.paramsData.slug != '' && (this.paramsData.slug == 'products' || this.paramsData.categoryslug != '')) {
        if (this.paramsData.slug != '' && ((this.paramsData.slug == 'products' && this.paramsData.categoryslug != '') || (this.paramsData.slug == 'products' && this.paramsData.categoryslug == '')) ) {
          if(typeof this.paramsData.categoryslug !== 'undefined' && this.paramsData.categoryslug != ''){
            var responseCat = await this.fetchCategoryBySlug(this.paramsData.categoryslug);
            if(typeof responseCat !== 'undefined' && responseCat.category_id != ''){
              this.componentToRender = markRaw(ProductsComponent);
            } else {
              this.setNotFoundPage();
            }
          } else {
            this.componentToRender = markRaw(ProductsComponent);
          }
        } else if(this.paramsData.slug != '' && this.paramsData.categoryslug == '') {
          var response = await this.fetchPageData(this.paramsData.slug);
          if (this.paramsData.slug != '' && typeof response !== 'undefined' &&  response.template_type !== null && response.template_type == 'gallery-images') {
            this.componentToRender = markRaw(ComingSoonComponent);
          } else {
            this.componentToRender = markRaw(CustomPageComponent);
          }
        } else {
          this.setNotFoundPage();
        }
      } catch (error) {
        console.error('Error determining component to render:', error);
        this.setNotFoundPage();
      }
    },
    async fetchPageData(slug) {
      try {
        const response = await axios.get(`/get-page-data/${slug}`);
        const responseData = response.data.data[0];
        if (responseData) {
          return responseData;
        } else {
          this.setNotFoundPage();
        }
      } catch (error) {
        console.error('Error fetching page data:', error);
        this.setNotFoundPage();
      }
    },
    async fetchCategoryBySlug(slug) {
      try {
        const response = await axios.get(`/get-categeory-by-slug/${slug}`);
        const responseData = response.data.data[0];
        if (responseData) {
          return responseData;
        } else {
          this.setNotFoundPage();
        }
      } catch (error) {
        console.error('Error fetching page data:', error);
        this.setNotFoundPage();
      }
    },
    setNotFoundPage() {
      this.componentToRender = markRaw(CustomPageComponent);
    }
  }
}
</script>