import { createApp } from 'vue';
import App from './App.vue';
import axios from 'axios';
import store from './services/store-api';
import smoothScroll from './directives/smooth-scroll'; // Import the directive
import isInViewport from './directives/is-in-viewport';

import 'bootstrap/dist/css/bootstrap.css';
import './assets/css/fonts.css';
import './assets/css/custom.css';

import 'jquery';
import 'popper.js';
import './assets/js/custom.js';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import { createRouter, createWebHistory } from 'vue-router';
import HomeComponent from './components/pages/Home'; 
import HomePageComponent from './components/pages/HomePage'; 
//import AboutUsComponent from './components/pages/AboutUs'; 
import PageComponent from './components/pages/Page';
//import ProductsComponent from './components/pages/Products'; 
//import OurProcessComponent from './components/pages/OurProcess';
import NotFoundComponent from './components/pages/NotFound';

//import { VueGtag } from 'vue-gtag-next';

const cache = {};
  
axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.interceptors.request.use(function (config) {
  config.headers['X-Lxg-Api-Key'] = process.env.VUE_APP_API_KEY;
  if(config.method == 'get' && config.url != '/set-csrf-token') {
  //if(config.method == 'get') {
    const cacheKey = `${config.method}:${config.url}`;
    if (cache[cacheKey] && cache[cacheKey].expiry > Date.now()) {
      config.data = cache[cacheKey].data; // Attach cached data to the config
      config.adapter = () => Promise.resolve({
        data: config.data,
        status: 200,
        statusText: 'OK',
        headers: {},
        config,
        request: {}
      });
    }
  }
  return config;
});

// Response interceptor to cache data
axios.interceptors.response.use(response => {
  if(response.config.method == 'get' && response.config.url != '/set-csrf-token') {
  //if(response.config.method == 'get') {
    const cacheKey = `${response.config.method}:${response.config.url}`;
    cache[cacheKey] = {
        data: response.data,
        expiry: Date.now() + 15 * 60 * 1000, // Cache for 15 minutes
    };
  }
  return response;
});
  
const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', name: 'Home', component: HomeComponent },
    { path: '/home', component: HomePageComponent },
    { path: '/:slug/:categoryslug?', name: 'Page', component: PageComponent },
    { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFoundComponent },
  ],
});

// Global error handler
window.onerror = function (message, source, lineno, colno, error) {
  console.error('Global error handler:', error);
  console.error('message:', message);
  console.error('source:', source);
  console.error('lineno:', lineno);
  console.error('colno:', colno);
  // Optionally, handle the error or report it to an error tracking service
  //return true;
};

const app = createApp(App);

// Initialize Google Analytics with vue-gtag-next
/*app.use(VueGtag, {
  property: {
    id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID // Replace with your Google Analytics tracking ID
  }
}, router);*/
app.directive('smooth-scroll', smoothScroll); // Register the directive
app.directive('is-in-viewport', isInViewport);
app.use(router).use(store).mount('#app');


